module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Simon's Blogchain","author":"Simon Sekavcnik","openGraph":{"type":"website","locale":"en_IE","url":"https://simon.sekavcnik.com/","site_name":"Simon's Blogchain","images":[{"url":"https://simon.sekavcnik.comogimage.png","width":1200,"height":630,"alt":"Og Image Alt"}]},"twitter":{"handle":"@SSekavcnik","site":"@SSekavcnik","cardType":"website","image":"https://simon.sekavcnik.comogimage.png"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics-gdpr/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-160700455-1","enableDevelopment":true,"anonymizeIP":true,"autoStartWithCookiesEnabled":false,"reactGaOptions":{"debug":true,"gaOptions":{"sampleRate":10}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
